@font-face {
  font-family: Roboto;
  src: url("../assets/Roboto/Roboto-Regular.ttf");
  font-weight: map-get($font-weights, "regular");
}

@font-face {
  font-family: Roboto;
  src: url("../assets/Roboto/Roboto-Medium.ttf");
  font-weight: map-get($font-weights, "medium");
}

@font-face {
  font-family: Roboto;
  src: url("../assets/Roboto/Roboto-Bold.ttf");
  font-weight: map-get($font-weights, "bold");
}
