/* make the customizations */
@import "./variables";
@import "./bootstrap";
@import "./roboto";

$theme-colors: (
        "info": tomato,
        "danger": darkred
);

// /* import bootstrap to set changes */
// @import "node_modules/bootstrap/scss/bootstrap";

/* my stuff */
.add-top-margin {
        margin-top: 20px;
}

.add-bottom-margin {
        margin-bottom: 20px;
}

.carousel-item > img { 
        width: 100%; 
      }
      
