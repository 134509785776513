// Grid
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 860px,
  xl: 860px
);

$grid-gutter-width: 20px;

// Colors
$primary: #3c9e37;
$secondary: #e9ecef;
$link-color: #00F;

// Common Stuff
$border-radius: 6px;
$component-active-bg: #000;

// Buttons and Inputs
$input-btn-padding-y: 0.322rem;
$input-btn-padding-x: 1.07rem;
$input-padding-x: 0.71rem;
$input-height: 2.18rem;
$input-height-sm: $input-height;
$input-height-lg: $input-height;

$input-bg: #f5f5f5;
$input-border-color: $input-bg;
$input-focus-border-color: $input-bg;
$input-placeholder-color: #AAA;

// Forms
$label-margin-bottom: .2rem;

// Cards
// $card-border-width: 0;
// $card-spacer-y: 40px;
// $card-spacer-x: 40px;

@import "node_modules/bootstrap/scss/bootstrap";
